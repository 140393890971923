.coffee-card {
  padding: 18px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.21);
  background-color: #ffffff;

  &__header {
    font-family: Calibre;
    display: flex;
    margin-bottom: 12px;

    h5 {
      font-size: 16px;
      font-weight: 500;
    }

    h3 {
      width: 299px;
      font-size: 24px;
      font-weight: bold;
    }
  }

  .coffee-selector__container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}