.edit-drawer__title {
  .drawerTitle {
    position: absolute;
    right: 0;
  }
}

.edit-drawer {
  min-width: 33vw;
  max-width: 66vw;
  padding: 28px;

  h4 {
    font-family: Calibre;
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 18px;
  }

  .edit-drawer__header {
    margin-bottom: 28px;

    p {
      margin: 8px 0;

      a {
        font-family: Platform;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
      }
    }
  }

  &__body {}

  &__menus {
    margin-top: 36px;

    h2 {
      font-family: Calibre;
      font-size: 24px;
    }
  }
}