$white: #ffffff;
$charcoal: #111111;

$body-font: 'Roboto', 'Space Mono', monospace;
$header-font: 'Muli', sans-serif;

$grey: ( 1: #EEEEEE );

$fonts: (
  'head': $header-font,
  'body': $body-font
);

*, *:before, *:after {
  box-sizing: border-box;
}
