.tasty-chips {
  .MuiLink-root {
    font-size: 16px;
    margin-left: 12px;
    font-family: Calibre;
  }

  .MuiChip-root {
    margin-right: 4px;
    margin-bottom: 4px;
  }
}