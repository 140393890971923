.login-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-form {
    max-width: 280px;
    min-height: 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
