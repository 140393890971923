@import '../../scss/globals';

.deallocator {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;

  .instructions {
    margin-bottom: 12px;

    h1, h2, p {
      margin-bottom: 12px;
    }

    ol, ul {
      padding-left: 8px;
    }

    li {
      list-style-type: decimal;
      margin: 0 0 12px 12px;
    }
  }

  .deallocator__empty-table {
    align-items: center;
    border-radius: 25px;
    border: 8px dashed $charcoal;
    display: flex;
    font-family: map-get($fonts, 'head');
    font-size: 18px;
    height: 400px;
    justify-content: center;
    margin: 20px 0;
    opacity: 0.2;
    text-transform: uppercase;
    width: 100%;
  }

  .deallocator__form-wrapper {
    padding: 20px 0;
    width: 100%;

    form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
    }

    input {
      font-family: map-get($fonts, 'body');
      letter-spacing: 1px;
      font-size: 14px;
      line-height: 1;
    }
  }

  .deallocator__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    svg {
      display: block;
      width: 40px;
      padding-left: 10px;
      fill: darkslategrey;
      transition: all ease-in-out .3s;
    }
  }
}