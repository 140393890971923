.codash__nav-overlay {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  position: fixed;

  &--hidden {
    display: none;
  }

  img {
    width: 100px;
    height: 100px;
  }

  button {
    text-transform: capitalize;
    margin: 12px 0;
    font-size: 24px;
  }
}

.codash__nav-toggle {
  display: flex;
  left: 40px;
  position: absolute;
  top: 40px;
  z-index: 3;
}

.menu {
  --front: #111111;
  --back: grey;
  --icon: white;

  perspective: 600px;
  width: 48px;
  height: 48px;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  input {
    display: none;

    & + div {
      span {
        --rotateY: 0deg;
        --background: var(--front);
        transform: rotateY(var(--rotateY));
        transform-style: preserve-3d;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: var(--background);
        backface-visibility: hidden;
        transition: transform .6s cubic-bezier(.2, .64, .48, 1.24);

        &:before,
        &:after {
          --rotate: 0deg;
          content: '';
          position: absolute;
          width: 16px;
          height: 2px;
          border-radius: 1px;
          top: 50%;
          left: 50%;
          background: var(--icon);
          backface-visibility: hidden;
          transform: translate(-50%, -50%) rotate(var(--rotate)) translateZ(6px);
        }

        &:first-child {
          --background: var(--back);

          &:before {
            --rotate: -45deg;
          }

          &:after {
            --rotate: 45deg;
          }
        }

        &:last-child {
          --rotateY: 180deg;

          &:before {
            box-shadow: 0 -5px 0 var(--icon), 0 5px 0 var(--icon);
          }

          &:after {
            display: none;
          }
        }
      }
    }

    &:checked + div {
      span {
        &:first-child {
          --rotateY: -180deg;
        }

        &:last-child {
          --rotateY: 0deg;
        }
      }
    }
  }
}
