.quiz-answers {
  ul {
    font-family: Calibre;

    li {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      span {
        display: block;

        &.question-text {
          font-weight: 600;
          font-family: Platform;
          font-size: 14px;
          line-height: 1.5;
        }

        &.answer-text {
          font-size: 18px;
        }
      }
    }
  }
}