.coffee-timeline {
  list-style: none;
  font-family: Calibre;

  li {
    display: flex;
    margin-bottom: 20px;

    .coffee-timeline__event-date {
      margin-right: 24px;
      font-size: 12px;
      color: #a1a0a0;
      padding-top: 4px;
    }

    .coffee-timeline__event-text {
      h3 {
        font-size: 20px;
        color: #000000;

        &.negative {
          color: red;
        }
      }

      ul {
        li {
          font-size: 14px;
          flex-direction: column;

          &.general-comments {
            font-style: italic;
          }
        }
      }
    }
  }
}