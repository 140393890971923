.total-entries-count {
  color: #808080;
  font-family: roboto;
  margin-bottom: 15px;
}

.load-more__container {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

.coffee-select-container {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    font-family: roboto;
    font-weight: 900;
    font-size: 18px;
    align-self: center;
  }

  .dialog-btn-wrapper {
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
  }

  &.verify {
    min-height: 75px;
    align-items: center;
  }
}

.coffee-select-wrapper {
  display: flex;

  .product-display-info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .bold {
      font-size: 10px;
    }

    span:nth-child(2) {
      font-size: 15px;
    }

    span:nth-child(3) {
      font-size: 12px;
    }
  }
}

.quiz-column {
  width: 170px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .comments {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.next-coffee-select {
  &__changes-pending {
    color: #ffd599;
  }
}