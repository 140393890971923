@import 'reset';
@import 'globals';
@import 'menu';

#root {
  background-color: $white;
}

.login-wrapper {
  display: flex;
  justify-content: center;

  button {
    background-color: red;
  }
}

.codash__wrapper {
  margin: 60px 0;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-family: 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif';
  font-size: 26px;
}

h2 {
  font-family: 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif';
  font-size: 20px;
}

p {
  font-family: 'Roboto', 'Helvetica Neue', 'Arial', 'sans-serif';
  font-size: 14px;
}

.bold {
  font-family: "Roboto";
  font-weight: 700;
}

.italic {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
}

.table__container {
  width: 95%;
  margin: 0 auto;

  table {
    border: 0px;
  }
}

.allocator__empty-table {
  align-items: center;
  border-radius: 25px;
  border: 8px dashed $charcoal;
  display: flex;
  font-family: map-get($fonts, 'head');
  font-size: 18px;
  height: 400px;
  justify-content: center;
  margin: 20px 0;
  opacity: 0.2;
  text-transform: uppercase;
  width: 100%;
}

table {
  border: 2px solid $charcoal;
  width: 100%;
  font-family: map-get($fonts, 'body');
}

th {
  border-bottom: 2px solid $charcoal;
  padding: 5px 0;
  text-align: center;
  font-family: map-get($fonts, 'head');
  text-transform: uppercase;
}

tr {
  background-color: map-get($grey, 1);

  &:nth-child(odd) {
    background-color: $white;
  }

  &.tr--error {
    background-color: red;
    color: $white;
    font-weight: bold;
  }

  &.tr--success {
    background-color: lightgreen;
    color: darkgreen;
    font-weight: bold;
    opacity: 0.5;
  }
}

td {
  text-align: center;
  padding: 5px 0;
}

.nav__close-toggle {
  border-radius: 50%;
  border: 1px solid $charcoal;
  height: 50px;
  left: 40px;
  position: absolute;
  text-align: center;
  top: 40px;
  width: 50px;
  cursor: pointer;
}

.allocator__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
}

.allocator__form-wrapper {
  padding: 20px 0;
  width: 100%;

  form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }

  input {
    font-family: map-get($fonts, 'body');
    letter-spacing: 1px;
    font-size: 14px;
    line-height: 1;
  }
}

.allocator__submit {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  svg {
    display: block;
    width: 40px;
    padding-left: 10px;
    fill: darkslategrey;
    transition: all ease-in-out .3s;
  }
}

.not-found__container {
  display: flex;
  justify-content: center;

  .not-found__text {
    font-family: roboto;
    font-size: 26px;
  }
}

.upload-form__container {
  height: 100px;
  width: 300px;

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }
}

.csv-upload {
  margin-left: 10px;
}

.MuiDialog-paper {
  padding: 25px 50px;
}

.preview-container {
  box-shadow: 1px 1px 5px grey;
  overflow: auto;
}

.receipt-upload-preview {
  border: none;

  thead {
    tr {
      background-color: red;
      color: white;

      th {
        width: 13%;
        font-family: roboto;
        font-size: 14px;
        border-bottom: none;
        padding: 16px;
        text-transform: capitalize;
        letter-spacing: 0.14994px;
      }
    }
  }

  tbody {
    td {
      padding: 16px;
      font-size: 14px;
    }
  }
}

.dialog-btn-wrapper {
  margin-bottom: 10px;

  button:nth-child(2) {
    margin-left: 10px;
  }
}
